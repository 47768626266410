:root {
  /* Colores principales */
  --primary-color: #007BFF;
  --primary-dark: #0056b3;
  --primary-light: #e3f2fd;
  --secondary-color: #6c757d;
  --secondary-dark: #495057;
  --secondary-light: #f8f9fa;

  /* Colores de texto */
  --text-primary: #2d3436;
  --text-secondary: #6c757d;
  --text-light: #ffffff;

  /* Colores de fondo */
  --background-primary: #ffffff;
  --background-secondary: #f8f9fa;
  --background-gradient: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  --background-dark: #343a40;

  /* Estados */
  --success-color: #28a745;
  --warning-color: #ffc107;
  --danger-color: #dc3545;
  --info-color: #17a2b8;

  /* Tipografía */
  --font-primary: 'Montserrat', sans-serif;
  --font-secondary: 'Roboto', sans-serif;
  --font-size-xs: 0.75rem;    /* 12px */
  --font-size-sm: 0.875rem;   /* 14px */
  --font-size-base: 1rem;     /* 16px */
  --font-size-lg: 1.125rem;   /* 18px */
  --font-size-xl: 1.25rem;    /* 20px */
  --font-size-2xl: 1.5rem;    /* 24px */
  --font-size-3xl: 1.875rem;  /* 30px */
  --font-size-4xl: 2.25rem;   /* 36px */
  --font-size-5xl: 3rem;      /* 48px */

  /* Espaciado */
  --spacing-xs: 0.25rem;      /* 4px */
  --spacing-sm: 0.5rem;       /* 8px */
  --spacing-md: 1rem;         /* 16px */
  --spacing-lg: 1.5rem;       /* 24px */
  --spacing-xl: 2rem;         /* 32px */
  --spacing-2xl: 3rem;        /* 48px */
  --spacing-3xl: 4rem;        /* 64px */

  /* Bordes y Sombras */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;
  --border-radius-xl: 16px;
  --border-radius-2xl: 20px;
  
  --box-shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --box-shadow-md: 0 4px 6px rgba(0, 0, 0, 0.1);
  --box-shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --box-shadow-xl: 0 20px 25px rgba(0, 0, 0, 0.15);

  /* Transiciones */
  --transition-fast: 0.2s ease;
  --transition-base: 0.3s ease;
  --transition-slow: 0.5s ease;

  /* Breakpoints */
  --breakpoint-xs: 320px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;

  /* Grid */
  --grid-columns: 12;
  --grid-gutter: var(--spacing-md);
  --container-padding: var(--spacing-md);
  --container-max-width: 1200px;
}

/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #4facfe transparent;
}

/* Estilos base */
html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: var(--font-secondary);
  color: var(--text-primary);
  background-color: var(--background-primary);
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Tipografía */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-primary);
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
}

h1 { font-size: var(--font-size-5xl); }
h2 { font-size: var(--font-size-4xl); }
h3 { font-size: var(--font-size-3xl); }
h4 { font-size: var(--font-size-2xl); }
h5 { font-size: var(--font-size-xl); }
h6 { font-size: var(--font-size-lg); }

p {
  margin-bottom: var(--spacing-md);
  color: var(--text-secondary);
}

/* Enlaces */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color var(--transition-base);
}

a:hover {
  color: var(--primary-dark);
}

/* Botones */
.btn {
  display: inline-block;
  padding: var(--spacing-sm) var(--spacing-lg);
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  font-weight: 500;
  text-align: center;
  border: none;
  border-radius: var(--border-radius-md);
  cursor: pointer;
  transition: all var(--transition-base);
}

.btn-primary {
  background-color: var(--primary-color);
  color: var(--text-light);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-md);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--text-light);
}

.btn-secondary:hover {
  background-color: var(--secondary-dark);
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-md);
}

/* Layout */
.container {
  width: 100%;
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding: 0 var(--container-padding);
}

.section {
  padding: var(--spacing-3xl) 0;
}

/* Grid System */
.grid {
  display: grid;
  gap: var(--grid-gutter);
  grid-template-columns: repeat(var(--grid-columns), 1fr);
}

.grid-auto-fit {
  display: grid;
  gap: var(--grid-gutter);
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

/* Utility Classes */
.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.d-flex { display: flex; }
.flex-column { flex-direction: column; }
.justify-center { justify-content: center; }
.align-center { align-items: center; }
.flex-wrap { flex-wrap: wrap; }

.mt-auto { margin-top: auto; }
.mb-auto { margin-bottom: auto; }
.mx-auto { margin-left: auto; margin-right: auto; }

/* Efectos */
.glass-effect {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Scrollbar personalizada */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: linear-gradient(to bottom, rgba(15, 23, 42, 0.95), rgba(30, 41, 59, 0.95));
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: padding-box;
  transition: all 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(135deg, #00f2fe 0%, #4facfe 100%);
  border-width: 2px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Efecto de brillo en el thumb */
::-webkit-scrollbar-thumb::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transform: translateX(-100%);
  animation: scrollThumbShine 3s ease-in-out infinite;
}

@keyframes scrollThumbShine {
  100% {
    transform: translateX(100%);
  }
}

/* Media Queries Estandarizados */
@media (min-width: var(--breakpoint-xs)) {
  .container { max-width: var(--breakpoint-xs); }
}

@media (min-width: var(--breakpoint-sm)) {
  .container { max-width: var(--breakpoint-sm); }
}

@media (min-width: var(--breakpoint-md)) {
  .container { max-width: var(--breakpoint-md); }
}

@media (min-width: var(--breakpoint-lg)) {
  .container { max-width: var(--breakpoint-lg); }
}

@media (min-width: var(--breakpoint-xl)) {
  .container { max-width: var(--container-max-width); }
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }

  .section {
    padding: var(--spacing-2xl) 0;
  }

  h1 { font-size: var(--font-size-4xl); }
  h2 { font-size: var(--font-size-3xl); }
  h3 { font-size: var(--font-size-2xl); }
  h4 { font-size: var(--font-size-xl); }
  h5 { font-size: var(--font-size-lg); }
  h6 { font-size: var(--font-size-base); }
}
