.how-we-work-section {
  padding: 5rem 0;
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  position: relative;
  overflow: hidden;
}

.how-we-work-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 30%, rgba(79, 172, 254, 0.1) 0%, transparent 25%),
    radial-gradient(circle at 80% 70%, rgba(0, 242, 254, 0.1) 0%, transparent 25%);
  pointer-events: none;
}

.how-we-work-section .section-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;
  z-index: 1;
}

.how-we-work-section .title-container {
  text-align: center;
  margin: 0 auto 5rem;
  max-width: 800px;
}

.how-we-work-section .section-title {
  font-size: 3.75rem;
  font-weight: 800;
  background: linear-gradient(135deg, #ffffff 0%, #e2e8f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.75rem;
  line-height: 1.1;
  position: relative;
  display: inline-block;
  letter-spacing: -0.02em;
}

.how-we-work-section .section-title .highlight {
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.how-we-work-section .section-title::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 4px;
  background: linear-gradient(90deg, #4facfe, #00f2fe);
  border-radius: 2px;
}

.how-we-work-section .section-subtitle {
  font-size: 1.375rem;
  color: #94a3b8;
  line-height: 1.6;
  margin: 0 auto;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.how-we-work-section .section-subtitle .highlight-text {
  color: #4facfe;
  font-weight: 600;
}

.how-we-work-section .steps-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  position: relative;
}

.how-we-work-section .step-card {
  background: rgba(30, 41, 59, 0.7);
  backdrop-filter: blur(8px);
  border-radius: 1.25rem;
  padding: 2.5rem;
  position: relative;
  border: 1px solid rgba(148, 163, 184, 0.1);
  transition: all 0.3s ease;
  overflow: hidden;
}

.how-we-work-section .step-card:hover {
  transform: translateY(-4px);
  border-color: rgba(148, 163, 184, 0.2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.how-we-work-section .step-icon-container {
  position: absolute;
  top: 0;
  right: 2.5rem;
  width: 4rem;
  height: 4rem;
  border-radius: 0 0 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-0.5rem);
  transition: transform 0.3s ease;
}

.how-we-work-section .step-card:hover .step-icon-container {
  transform: translateY(0);
}

.how-we-work-section .step-icon {
  font-size: 1.75rem;
  color: #ffffff;
}

.how-we-work-section .step-number {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
  margin-bottom: 1.25rem;
  letter-spacing: -0.03em;
  opacity: 0.9;
  position: relative;
  display: inline-block;
  transition: opacity 0.3s ease;
}

.how-we-work-section .step-card:hover .step-number {
  opacity: 1;
}

.how-we-work-section .step-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1rem;
  line-height: 1.2;
  letter-spacing: -0.01em;
}

.how-we-work-section .step-description {
  color: #94a3b8;
  line-height: 1.7;
  font-size: 1.125rem;
  font-weight: 400;
}

.how-we-work-section .step-arrow {
  display: none;
  position: absolute;
  color: #3b82f6;
  font-size: 1.5rem;
  z-index: 10;
}

.cta-container {
  text-align: center;
  margin-top: 4rem;
  padding-top: 2rem;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  padding: 1.25rem 3rem;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  border-radius: 3rem;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(59, 130, 246, 0.25);
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.1) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.cta-button:hover {
  background: linear-gradient(135deg, #1d4ed8 0%, #1e40af 100%);
  box-shadow: 0 8px 25px rgba(59, 130, 246, 0.4);
  color: #f0f9ff;
}

.cta-button:hover::before {
  opacity: 1;
}

.cta-button span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

/* Estilo específico para el ícono CTA del botón en How We Work */
.how-we-work-section .cta-button .cta-icon {
  margin-left: 1rem;
  font-size: 1.125rem;
  transition: transform 0.3s ease;
  opacity: 0.9;
}

.how-we-work-section .cta-button:hover .cta-icon {
  transform: translateX(5px);
  opacity: 1;
}

@media (max-width: 767px) {
  .how-we-work-section .section-title {
    font-size: 2.75rem;
  }

  .how-we-work-section .section-subtitle {
    font-size: 1.25rem;
  }

  .how-we-work-section .step-card {
    padding: 2rem;
  }

  .how-we-work-section .step-icon-container {
    right: 2rem;
    width: 3.5rem;
    height: 3.5rem;
  }

  .how-we-work-section .step-number {
    font-size: 3.5rem;
  }

  .how-we-work-section .step-title {
    font-size: 1.375rem;
  }

  .how-we-work-section .step-description {
    font-size: 1rem;
  }

  .how-we-work-section .step-arrow {
    display: block;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .cta-container {
    margin-top: 3rem;
  }

  .cta-button {
    padding: 1.125rem 2.5rem;
    font-size: 1.125rem;
  }

  .cta-icon {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .how-we-work-section .steps-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .how-we-work-section .step-arrow {
    display: block;
    right: -1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 1024px) {
  .how-we-work-section .section-title {
    font-size: 3.75rem;
  }

  .how-we-work-section .steps-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .how-we-work-section .step-card {
    padding: 2.5rem;
  }
}
