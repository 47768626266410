@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

.construction-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  background: radial-gradient(circle at center,
    #1a1a2e 0%,
    #16213e 50%,
    #0a0f1c 100%
  );
}

.animated-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.stars-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(1px 1px at 10% 10%, rgba(255, 255, 255, 0.1) 50%, transparent 100%),
    radial-gradient(2px 2px at 20% 20%, rgba(255, 255, 255, 0.2) 50%, transparent 100%),
    radial-gradient(1px 1px at 30% 30%, rgba(255, 255, 255, 0.15) 50%, transparent 100%),
    radial-gradient(2px 2px at 40% 40%, rgba(255, 255, 255, 0.1) 50%, transparent 100%),
    radial-gradient(1px 1px at 50% 50%, rgba(255, 255, 255, 0.12) 50%, transparent 100%);
  background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
  animation: starsMovement 120s linear infinite;
}

.nebula-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: 
    radial-gradient(circle at 20% 30%, rgba(79, 172, 254, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 80% 70%, rgba(0, 242, 254, 0.1) 0%, transparent 50%),
    radial-gradient(circle at 50% 50%, rgba(0, 102, 255, 0.1) 0%, transparent 50%);
  filter: blur(30px);
  opacity: 0.5;
  mix-blend-mode: screen;
  animation: nebulaMovement 60s ease-in-out infinite alternate;
}

.particle {
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: #fff;
  border-radius: 50%;
  opacity: var(--brightness);
  animation: 
    particleFloat var(--speed) linear infinite,
    particlePulse var(--pulse-speed) ease-in-out infinite alternate;
  left: var(--x-offset);
  top: var(--y-offset);
}

.particle.star {
  background: #fff;
  box-shadow: 
    0 0 4px #fff,
    0 0 8px #fff,
    0 0 12px rgba(79, 172, 254, 0.8);
  animation: 
    particleFloat var(--speed) linear infinite,
    starTwinkle var(--pulse-speed) ease-in-out infinite alternate;
}

@keyframes particleFloat {
  0% {
    transform: translateY(100vh) translateX(-100px) scale(0);
    opacity: 0;
  }
  10% {
    opacity: var(--brightness);
  }
  90% {
    opacity: var(--brightness);
  }
  100% {
    transform: translateY(-20vh) translateX(100px) scale(1);
    opacity: 0;
  }
}

@keyframes particlePulse {
  0% {
    transform: scale(1);
    opacity: var(--brightness);
  }
  100% {
    transform: scale(1.5);
    opacity: calc(var(--brightness) * 0.6);
  }
}

@keyframes starTwinkle {
  0% {
    transform: scale(1);
    opacity: var(--brightness);
    filter: blur(0px);
  }
  50% {
    transform: scale(1.2);
    opacity: calc(var(--brightness) * 1.2);
    filter: blur(1px);
  }
  100% {
    transform: scale(0.8);
    opacity: calc(var(--brightness) * 0.8);
    filter: blur(0px);
  }
}

@keyframes starsMovement {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes nebulaMovement {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.2) rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 0.3;
  }
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 2;
}

.main-logo {
  max-width: 260px;
  height: auto;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.15));
  transition: all 0.3s ease;
}

.main-logo:hover {
  filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.3));
  transform: scale(1.02);
}

.construction-content {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.2),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.main-heading {
  font-size: clamp(2rem, 4vw, 3rem);
  font-weight: 600;
  margin: 0.5rem 0;
  background: linear-gradient(135deg, #fff 0%, #e0e0e0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sub-heading {
  font-size: clamp(1.1rem, 2vw, 1.3rem);
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  width: 100%;
  margin-top: 1rem;
}

.feature {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.feature:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.07);
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.15);
}

.feature-icon-wrapper {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  margin-bottom: 1rem;
  position: relative;
}

.feature-icon {
  font-size: 1.8rem;
  color: #fff;
  z-index: 2;
}

.feature-icon-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  opacity: 0.2;
  animation: rotate 10s linear infinite;
}

.feature h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.5rem 0;
  color: #fff;
}

.feature p {
  font-size: 1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
}

.feature-decoration {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  transform: translateX(-100%);
  animation: slide 3s ease infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .construction-container {
    padding: 1rem;
  }
  
  .main-logo {
    max-width: 200px;
  }
  
  .construction-content {
    padding: 2rem 1.5rem;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  .feature {
    padding: 1.5rem;
  }
  
  .main-heading {
    font-size: clamp(1.8rem, 3vw, 2.5rem);
  }
  
  .sub-heading {
    font-size: clamp(1rem, 1.8vw, 1.2rem);
    margin-bottom: 2rem;
  }
}
