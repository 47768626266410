.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 1;
}

.hero-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
  will-change: transform;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: cover;
  filter: brightness(0.4);
  transition: all 1.5s ease-out;
  will-change: transform;
}

.hero-video:hover {
  filter: brightness(0.5);
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(0, 123, 255, 0.1) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  z-index: 0;
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 40px;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  min-height: calc(100vh - 80px);
}

.title-container {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.hero-title {
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(1.8rem, 4.5vw, 3.2rem);
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: -0.5px;
  margin: 0;
  color: rgba(255, 255, 255, 0.95);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.hero-title-text {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
}

.company-name {
  display: inline;
  font-size: clamp(2.2rem, 5.5vw, 4rem);
  font-weight: 800;
  letter-spacing: 1px;
  background: linear-gradient(45deg, 
    #007bff 0%,
    #00ff88 50%,
    #007bff 100%
  );
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 4s ease infinite;
  text-transform: uppercase;
}

.company-name::before {
  content: '';
  position: absolute;
  top: -15%;
  left: -5%;
  right: -5%;
  bottom: -15%;
  background: radial-gradient(
    circle at center,
    rgba(0, 123, 255, 0.08),
    transparent 70%
  );
  animation: pulse 3s ease-in-out infinite;
  border-radius: 10px;
  z-index: -1;
}

.hero-slogan {
  font-size: clamp(1rem, 2vw, 1.2rem);
  line-height: 1.6;
  max-width: 800px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: 300;
  letter-spacing: 0.3px;
}

.search-bar-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  height: 60px;
}

.search-bar {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 30px;
  padding: 0.8rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 0.8);
}

.search-icon {
  font-size: 1.6rem;
  color: #007bff;
  filter: drop-shadow(0 0 10px rgba(0, 123, 255, 0.5));
}

.typing-text {
  color: #00ff88;
  font-weight: 500;
  font-size: clamp(1.2rem, 3vw, 1.8rem);
  height: 1.5em;
  display: inline-flex;
  align-items: center;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1.2em;
  background-color: #00ff88;
  margin-left: 4px;
  animation: blink 1s infinite;
}

.hero-message {
  font-size: clamp(1rem, 2vw, 1.3rem);
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 0 1rem;
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.hero-button {
  padding: 0.8em 1.8em;
  font-size: clamp(0.9rem, 2vw, 1.1rem);
  font-weight: 600;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  min-width: 200px;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.hero-button.primary {
  background: linear-gradient(45deg, #007bff, #00ff88);
  color: white;
  box-shadow: 0 5px 20px rgba(0, 123, 255, 0.4);
}

.hero-button.primary::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.hero-button.primary:hover::before {
  left: 100%;
}

.hero-button.secondary {
  background: rgba(255, 255, 255, 0.08);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}

.hero-button:hover {
  transform: translateY(-2px);
}

.hero-button.primary:hover {
  box-shadow: 0 8px 25px rgba(0, 123, 255, 0.5);
}

.hero-button.secondary:hover {
  background: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.3);
}

.button-icon {
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.hero-button:hover .button-icon {
  transform: translateX(5px);
}

.scroll-indicator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2rem;
  margin: 0 auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  opacity: 0.8;
  transition: opacity 0.3s ease;
  cursor: pointer;
  z-index: 10;
}

.scroll-indicator:hover {
  opacity: 1;
}

.mouse {
  width: 26px;
  height: 40px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  position: relative;
}

.wheel {
  background-color: rgba(255, 255, 255, 0.8);
  width: 4px;
  height: 8px;
  position: absolute;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  animation: scroll 1.5s ease-in-out infinite;
}

.scroll-text {
  font-size: 0.8rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.6;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes pulse {
  0%, 100% { transform: scale(1); opacity: 0.2; }
  50% { transform: scale(1.03); opacity: 0.4; }
}

@keyframes scroll {
  0% { transform: translate(-50%, 0); opacity: 1; }
  100% { transform: translate(-50%, 15px); opacity: 0; }
}

@media (max-width: 1200px) {
  .hero-content {
    padding: 0 20px;
    gap: 2rem;
  }

  .search-bar {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 60px 15px;
  }

  .hero-content {
    gap: 1.5rem;
  }

  .company-name::before {
    top: -10%;
    bottom: -10%;
  }

  .search-bar {
    padding: 1.2rem;
    gap: 1rem;
  }

  .hero-buttons {
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    gap: 1rem;
    margin-bottom: 3rem;
  }

  .hero-button {
    width: 100%;
    min-width: unset;
    padding: 0.9rem 1.5rem;
  }
}

@media (max-width: 480px) {
  .hero-section {
    padding: 40px 10px;
  }

  .hero-content {
    gap: 1.2rem;
  }

  .search-bar {
    padding: 1rem;
  }

  .search-icon-container {
    width: 35px;
    height: 35px;
  }

  .search-icon {
    font-size: 1.2rem;
  }

  .company-name::before {
    display: none;
  }

  .hero-buttons {
    margin-bottom: 2rem;
  }
}

@media (max-height: 700px) {
  .hero-section {
    padding: 40px 20px;
  }

  .hero-content {
    gap: 1.2rem;
  }

  .scroll-indicator {
    display: none;
  }
}
