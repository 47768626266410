/* Estilos para la sección de testimonios del home */
.home-testimonials-section {
  padding: 5rem 0;
  background-color: var(--background);
  position: relative;
}

.testimonials-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-container {
  text-align: center;
  margin: 0 auto 5rem;
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  font-size: 3.5rem;
  font-weight: 800;
  text-align: center;
  background: linear-gradient(135deg, 
    #2980b9 0%,
    #3498db 25%,
    #2ecc71 50%,
    #3498db 75%,
    #2980b9 100%
  );
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 1.75rem;
  line-height: 1.1;
  position: relative;
  display: inline-block;
  letter-spacing: -0.02em;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 4px;
  background: linear-gradient(90deg, #2980b9, #2ecc71);
  border-radius: 2px;
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.section-description {
  font-size: 1.375rem;
  text-align: center;
  color: var(--text-secondary);
  max-width: 800px;
  margin: 2rem auto 0;
  line-height: 1.6;
  opacity: 0.9;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.testimonial-card {
  background: white;
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.testimonial-quote-icon {
  font-size: 2rem;
  color: #007bff;
  opacity: 0.1;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.testimonial-content {
  margin-bottom: 2rem;
}

.testimonial-content p {
  font-size: 1.1rem;
  line-height: 1.7;
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.testimonial-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.testimonial-service span {
  display: inline-block;
  font-size: 0.95rem;
  color: #3498db;
  font-weight: 500;
  background: rgba(52, 152, 219, 0.08);
  padding: 0.4rem 1rem;
  border-radius: 20px;
}

.testimonial-rating {
  display: flex;
  gap: 0.3rem;
}

.star-icon {
  color: #ffd700;
  font-size: 1.1rem;
}

.testimonial-author {
  display: flex;
  align-items: center;
  border-top: 1px solid #edf2f7;
  padding-top: 1.5rem;
}

.testimonial-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.testimonial-info h4 {
  margin: 0;
  color: #2d3748;
  font-size: 1.2rem;
  font-weight: 600;
}

.testimonial-info p {
  margin: 0.3rem 0 0;
  color: #718096;
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .home-testimonials-section {
    padding: 4rem 0;
  }

  .section-title {
    font-size: 2.2rem;
  }

  .section-description {
    font-size: 1.1rem;
    margin: 1.5rem auto 0;
  }

  .testimonials-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .testimonial-card {
    padding: 2rem;
  }
}
