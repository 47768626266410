.blog-section {
  position: relative;
  padding: 100px 0;
  background: linear-gradient(135deg, #0056b3 0%, #007bff 100%);
  color: white;
  overflow: hidden;
  font-family: var(--font-primary);
}

.blog-background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  pointer-events: none;
  background-image: radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.15) 1px, transparent 0);
  background-size: 20px 20px;
}

.blog-section .section-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-section .section-title {
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 20px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  letter-spacing: -1px;
  line-height: 1.2;
  font-family: var(--font-primary);
}

/* Eliminar cualquier underline automático */
.blog-section .section-title::after {
  display: none;
}

.blog-section .title-underline {
  height: 4px;
  background: linear-gradient(90deg, transparent, #FFD700, transparent);
  margin-bottom: 25px;
  border-radius: 2px;
  width: 120px;
}

.blog-section .section-subtitle {
  font-size: 1.35rem;
  color: rgba(255, 255, 255, 0.95);
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
  font-weight: 400;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  font-family: var(--font-secondary);
}

.blog-section .blog-posts-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 50px;
}

.blog-section .blog-post {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.blog-section .post-image {
  position: relative;
  width: 100%;
  height: 220px;
  overflow: hidden;
}

.blog-section .post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.blog-section .post-category {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.9);
  color: #007bff;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 600;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.blog-section .post-content {
  padding: 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.blog-section .post-meta {
  display: flex;
  gap: 20px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  margin-bottom: 20px;
  font-family: var(--font-secondary);
}

.blog-section .post-meta span {
  display: flex;
  align-items: center;
  gap: 6px;
}

.blog-section .post-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  margin-bottom: 15px;
  line-height: 1.3;
  font-family: var(--font-primary);
}

.blog-section .post-excerpt {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 25px;
  line-height: 1.6;
  font-size: 1.05rem;
  font-family: var(--font-secondary);
  flex-grow: 1;
}

.read-more-container {
  margin-top: auto;
  text-align: right;
  padding-top: 20px;
  position: relative;
}

.read-more-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  padding: 8px 0;
  font-weight: 500;
  font-size: 0.95rem;
  font-family: var(--font-primary);
  position: relative;
  transition: all 0.3s ease;
}

.read-more-button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.3s ease;
}

.read-more-button::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #FFD700;
  transition: all 0.3s ease;
}

.read-more-button:hover {
  color: #FFD700;
}

.read-more-button:hover::before {
  width: 100%;
}

.read-more-button .arrow-icon {
  font-size: 0.8em;
  transition: transform 0.3s ease;
  position: relative;
  top: 1px;
}

.read-more-button:hover .arrow-icon {
  transform: translateX(5px);
  color: #FFD700;
}

.view-all-container {
  text-align: center;
  margin-top: 60px;
}

.view-all-button {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: transparent;
  color: white;
  text-decoration: none;
  padding: 15px 35px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid rgba(255, 255, 255, 0.8);
  font-family: var(--font-primary);
  position: relative;
  overflow: hidden;
}

.view-all-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.view-all-button:hover {
  border-color: #FFD700;
  color: #FFD700;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.view-all-button:hover::before {
  transform: translateX(0);
}

.view-all-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 992px) {
  .blog-section .blog-posts-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .blog-section .section-title {
    font-size: 3rem;
  }
  
  .blog-section .section-subtitle {
    font-size: 1.25rem;
  }

  .blog-section .post-title {
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .blog-section {
    padding: 80px 0;
  }

  .blog-section .blog-posts-container {
    grid-template-columns: 1fr;
  }

  .blog-section .section-title {
    font-size: 2.5rem;
    letter-spacing: -0.5px;
  }

  .blog-section .section-subtitle {
    font-size: 1.15rem;
    padding: 0 20px;
  }

  .blog-section .post-image {
    height: 200px;
  }

  .blog-section .post-title {
    font-size: 1.3rem;
  }
}

@media (max-width: 480px) {
  .blog-section {
    padding: 60px 0;
  }

  .blog-section .section-title {
    font-size: 2rem;
  }

  .blog-section .section-subtitle {
    font-size: 1.1rem;
  }

  .blog-section .post-meta {
    flex-direction: column;
    gap: 10px;
  }

  .read-more-container {
    text-align: left;
    padding-top: 15px;
  }

  .read-more-button {
    font-size: 0.9rem;
    padding: 6px 0;
  }

  .view-all-button {
    width: 90%;
    justify-content: center;
    font-size: 1rem;
    padding: 12px 25px;
  }
}
