/* ==========================================================================
   Home Page - Estilos Generales
   ========================================================================== */

/* Layout Principal
   ========================================================================== */
.home-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.home-sections-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Configuración Base de Secciones
   ========================================================================== */
.home-sections-container > section {
    width: 100%;
    position: relative;
    padding: var(--spacing-3xl) 0;
}

.section-content {
    max-width: var(--container-max-width);
    margin: 0 auto;
    padding: 0 var(--spacing-md);
    width: 100%;
}

/* Componentes Comunes
   ========================================================================== */
.section-title {
    font-size: 3.5rem;
    font-weight: 800;
    text-align: center;
    margin-bottom: 0.875rem;
    line-height: 1.1;
    position: relative;
    display: inline-block;
    letter-spacing: -0.02em;
}

.section-description {
    font-size: 1.375rem;
    text-align: center;
    color: var(--text-secondary);
    max-width: 800px;
    margin: 0.75rem auto 0;
    line-height: 1.6;
    opacity: 0.9;
    font-weight: 400;
    letter-spacing: -0.01em;
}

/* Utilidades
   ========================================================================== */
.text-gradient {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.glass-effect {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

/* Media Queries Generales
   ========================================================================== */
@media (max-width: 1200px) {
    .section-content {
        padding: 0 var(--spacing-lg);
    }
}

@media (max-width: 768px) {
    .home-sections-container > section {
        padding: var(--spacing-2xl) 0;
    }
    
    .section-title {
        font-size: 2.5rem;
    }
    
    .section-description {
        font-size: 1.125rem;
    }
}

@media (max-width: 480px) {
    .section-content {
        padding: 0 var(--spacing-md);
    }
}
