/* Estilos para la sección de servicios */
.services-section {
  padding: 5rem 0;
  background-color: var(--background);
  position: relative;
}

.services-section .title-container {
  text-align: center;
  margin: 0 auto 5rem;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 10% 10%, rgba(52, 152, 219, 0.03) 0%, transparent 50%),
    radial-gradient(circle at 90% 90%, rgba(46, 204, 113, 0.03) 0%, transparent 50%);
  z-index: 1;
}

.services-content {
  position: relative;
  width: 100%;
  max-width: var(--container-max-width);
  margin: 0 auto;
  padding: 0 var(--spacing-md);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  font-size: 3.5rem;
  font-weight: 800;
  text-align: center;
  background: linear-gradient(135deg, 
    #2980b9 0%,
    #3498db 25%,
    #2ecc71 50%,
    #3498db 75%,
    #2980b9 100%
  );
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 0.875rem;
  line-height: 1.1;
  position: relative;
  display: inline-block;
  letter-spacing: -0.02em;
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 4px;
  background: linear-gradient(90deg, #2980b9, #2ecc71);
  border-radius: 2px;
}

.section-description {
  font-size: 1.375rem;
  text-align: center;
  color: var(--text-secondary);
  max-width: 800px;
  margin: 0.75rem auto 0;
  line-height: 1.6;
  opacity: 0.9;
  font-weight: 400;
  letter-spacing: -0.01em;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-xl);
  width: 100%;
  max-width: var(--max-width-xl);
  margin: 0 auto;
}

.service-area-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  isolation: isolate;
}

.service-area-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.service-area-card:hover::before {
  opacity: 1;
}

.card-content {
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.area-header {
  text-align: center;
  margin-bottom: 2rem;
}

.area-icon {
  width: 70px;
  height: 70px;
  margin: 0 auto 1rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
}

.area-icon::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 0%,
    transparent 100%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.area-icon:hover::after {
  opacity: 1;
}

.area-header h3 {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 700;
  background: linear-gradient(135deg, #2c3e50, #34495e);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: -0.02em;
}

.area-subtitle {
  display: block;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #5d7b97;
  letter-spacing: -0.01em;
}

.area-description {
  color: #64748b;
  font-size: 1.05rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  letter-spacing: -0.01em;
}

.services-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}

.service-item {
  padding: 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.service-area-card[data-area="organization"] .service-item:hover {
  background: rgba(52, 152, 219, 0.1);
}

.service-area-card[data-area="design"] .service-item:hover {
  background: rgba(231, 76, 60, 0.1);
}

.service-area-card[data-area="automation"] .service-item:hover {
  background: rgba(46, 204, 113, 0.1);
}

.service-item a {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.service-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  flex-shrink: 0;
}

.service-info {
  flex-grow: 1;
}

.service-info h4 {
  font-size: 1.1rem;
  margin-bottom: 0.4rem;
  font-weight: 600;
  color: #334155;
  letter-spacing: -0.01em;
  line-height: 1.4;
}

.service-info p {
  font-size: 0.95rem;
  color: #64748b;
  line-height: 1.5;
  letter-spacing: -0.005em;
}

.service-area-card[data-area="organization"] .area-header h3 {
  background: linear-gradient(135deg, #2980b9, #3498db);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.service-area-card[data-area="design"] .area-header h3 {
  background: linear-gradient(135deg, #c0392b, #e74c3c);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.service-area-card[data-area="automation"] .area-header h3 {
  background: linear-gradient(135deg, #27ae60, #2ecc71);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.service-item:hover .service-info h4 {
  color: inherit;
  transition: color 0.3s ease;
}

.service-area-card[data-area="organization"] .service-item:hover .service-info h4 {
  color: #2980b9;
}

.service-area-card[data-area="design"] .service-item:hover .service-info h4 {
  color: #e74c3c;
}

.service-area-card[data-area="automation"] .service-item:hover .service-info h4 {
  color: #2ecc71;
}

.cta-card {
  background: linear-gradient(135deg, #2980b9, #2ecc71);
  border-radius: 1rem;
  padding: 2rem;
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.services-section .cta-card .cta-icon {
  font-size: 5rem;
  color: rgba(255, 255, 255, 0.9);
  animation: float 3s ease-in-out infinite;
}

.cta-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(120deg, 
    #fff 0%,
    #fff7e6 25%,
    #ffffff 50%,
    #fff7e6 75%,
    #fff 100%);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
}

.cta-description {
  font-size: 1.1rem;
  max-width: 100%;
  margin: 0;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.95);
}

.cta-buttons {
  display: flex;
  gap: 1.25rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: auto;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.5rem;
  border-radius: 0.75rem;
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.01em;
}

.cta-button.primary {
  background: white;
  color: #2980b9;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.cta-button.primary:hover {
  background: rgba(255, 255, 255, 0.95);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  color: #2471a3;
}

.cta-button.primary:active {
  transform: translateY(0);
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.cta-button.secondary {
  background: rgba(255, 255, 255, 0.15);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
}

.cta-button.secondary:hover {
  background: rgba(255, 255, 255, 0.25);
  border-color: rgba(255, 255, 255, 0.4);
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.cta-button.secondary:active {
  transform: translateY(0);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.button-icon {
  font-size: 1.1rem;
  transition: transform 0.3s ease;
}

.cta-button:hover .button-icon {
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .services-content {
    padding: 0 var(--spacing-md);
    gap: 3rem;
  }

  .services-container {
    grid-template-columns: 1fr;
    gap: var(--spacing-lg);
  }

  .service-area-card {
    margin-bottom: var(--spacing-md);
  }

  .cta-card {
    padding: 2rem;
  }

  .area-header h3 {
    font-size: 1.5rem;
  }

  .area-subtitle {
    font-size: 1rem;
  }

  .area-description {
    font-size: 1rem;
  }

  .service-info h4 {
    font-size: 1rem;
  }

  .service-info p {
    font-size: 0.9rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
  }
  
  .button-icon {
    font-size: 1rem;
  }

  .services-section .cta-card .cta-icon {
    font-size: 5rem;
  }
}

@keyframes float {
  0%, 100% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes shine {
  to { background-position: 200% center; }
}
